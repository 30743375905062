// Import libraries
import React from 'react';
import PropTypes from 'prop-types';


// Import components
import Header from './Header';
import Footer from './Footer';


// Create component
const Layout = ({ children, withBanner, removeFooter }) => {
    return (
        <React.Fragment>
            <Header
                withBanner={withBanner}
            />
            <main>{children}</main>
            {(removeFooter === undefined || (removeFooter === false)) && <Footer />}
        </React.Fragment>
    );
};


// Assign prop types to component
Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};


// Export component by default
export default Layout;